import { z } from 'zod';

export type BrandProfile = {
  id: string;
  name: string;
  usp: string;
  audience: string;
  language: string;
  story: string;
  otherInfo: string;
  textSample: string;
  logoSrc?: string;
  analyticsProfiles: {
    profileId: string;
    pageType: number;
  }[];
  createdAt: string;
  updatedAt: string;
};

export type Creative = {
  id: string;
  brandProfileId: string;
  pageType: number;
  goal: string;
  text: string;
  createdAt: string;
  updatedAt: string;
};

export const WebCrawlResultSchema = z.object({
  url: z.string(),
  title: z.string(),
  description: z.string().optional(),
  image: z.string().optional(),
  logo: z.string().optional(),
  keywords: z.string().optional(),
  h1: z.array(z.string()),
  h2: z.array(z.string()),
  h3: z.array(z.string()),
  paragraphs: z.array(z.string()),
  socialTags: z.array(
    z.object({
      property: z.string(),
      content: z.string().optional(),
    }),
  ),
  bodyText: z.string(),
  bodyImages: z.array(z.string()),
  articleText: z.string(),
  articleImages: z.array(z.string()),
});

export type WebCrawlResult = z.infer<typeof WebCrawlResultSchema>;

export interface PageNode {
  id: string;
  _id: string;
  username: string | null;
  name: string;
  _type: number;
  fans: number | null;
  picture: string | null;
}

export interface PageEdge {
  node: PageNode;
}

export interface PageConnection {
  edges: PageEdge[];
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: string;
  };
}
