import { get, httpDelete, post, put } from 'api/client';
import config from 'config';
import { getExtendedTimeout } from 'utils/request';
import { createSearchFromParams } from 'utils/url';
import { MediaApi } from 'api/media';
import { imageToMediaLibraryItem } from 'utils/mediaLibrary';
import { BrandHubConfig } from 'app/config/brandHub';
import {
  BrandProfile,
  Creative,
  WebCrawlResult,
  PageConnection,
} from 'app/modules/brandHub/types';

export const BrandHubApi = {
  createBrandProfile(
    data: Omit<BrandProfile, 'id' | 'createdAt' | 'updatedAt' | 'logoSrc'>,
  ): Promise<BrandProfile> {
    return post('/brand-profiles', data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  updateBrandProfile(
    id: number | string,
    data: Omit<BrandProfile, 'id' | 'createdAt' | 'updatedAt' | 'logoSrc'>,
  ): Promise<BrandProfile> {
    return put(`/brand-profiles/${id}`, data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  async saveBrandProfile({
    logoSrc,
    ...data
  }: Omit<BrandProfile, 'id' | 'createdAt' | 'updatedAt' | 'logoSrc'> & {
    id?: string;
    logoSrc: string | File | null;
  }): Promise<BrandProfile> {
    const newLogoSrc = await (async () => {
      if (logoSrc instanceof File) {
        const response = await MediaApi.uploadImage({
          file: logoSrc,
        });

        return imageToMediaLibraryItem(Object.values(response)[0])?.data
          .thumbnail.src;
      }

      return logoSrc;
    })();

    const completeData = {
      ...data,
      logoSrc: newLogoSrc,
    };

    if (completeData.id) {
      return BrandHubApi.updateBrandProfile(
        completeData.id.toString(),
        completeData,
      );
    }
    return BrandHubApi.createBrandProfile(completeData);
  },
  deleteBrandProfile(id: string | number): Promise<void> {
    return httpDelete(`/brand-profiles/${id}`, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  getOneBrandProfile(id: string | number): Promise<BrandProfile> {
    return get(`/brand-profiles/${id}`, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  getAllBrandProfiles(): Promise<BrandProfile[]> {
    return get('/brand-profiles', {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  suggestBrandProfile(data: {
    postsText?: string;
    bodyText?: string;
    planType: string;
    logo?: string;
    analyticsProfiles?: { profileId: string; pageType: number }[];
  }): Promise<BrandProfile> {
    return post('/suggest-brand-profiles', data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
      timeout: getExtendedTimeout(5),
    });
  },
  creativesOptions(): Promise<{
    creativeLength: {
      value: string;
      label: string;
    }[];
    toneOfVoice: {
      value: string;
      label: string;
    }[];
    counts: {
      value: number;
      label: string;
    }[];
  }> {
    return get('/creatives/options', {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  createCreative({
    webCrawl,
    ...data
  }: {
    brandProfileId?: string;
    goal: string;
    pageType?: number;
    planType: string;
    creativeLength?: string;
    language?: string | null;
    toneOfVoice?: string;
    webCrawl?: WebCrawlResult;
  }): Promise<Creative> {
    return post(
      '/creatives',
      {
        ...data,
        referenceText: webCrawl?.articleText
          ? `URL: ${webCrawl?.url}, Text: ${webCrawl?.articleText}`
          : undefined,
      },
      {
        baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
        timeout: getExtendedTimeout(5),
      },
    );
  },
  deleteCreative(id: string | number): Promise<void> {
    return httpDelete(`/creatives/${id}`, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  updateCreative({
    id,
    ...data
  }: Partial<Creative> & Pick<Creative, 'id'>): Promise<Creative> {
    return put(`/creatives/${id}`, data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  getAllCreatives(data: { brandProfileId: string; page?: number }): Promise<
    ApiResponse<
      Creative[],
      {
        currentPage: number;
        firstPage: number;
        lastPage: number;
        perPage: number;
      }
    >
  > {
    return get(
      `/creatives${createSearchFromParams({
        brandProfileId: data.brandProfileId,
        page: data.page,
        perPage: BrandHubConfig.CREATIVES_PAGE_SIZE,
      })}`,
      {
        baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
      },
    );
  },
  fetchWebCrawl(data: { url: string }): Promise<WebCrawlResult> {
    return post(`/web-crawls`, data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
  getAnalyticsProfiles({
    first = -1,
  }: {
    first?: number;
  } = {}): Promise<PageConnection> {
    return get(
      `/analytics/profiles${createSearchFromParams({
        first,
      })}`,
      {
        baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
      },
    );
  },
};
